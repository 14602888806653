define("discourse/plugins/discourse-envato/discourse/initializers/extend-for-envato", ["exports", "jquery", "discourse/lib/url"], function (_exports, _jquery, _url) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "extend-for-envato",
    initialize(container) {
      const username = container.lookup("service:current-user")?.username;
      if (username) {
        (0, _jquery.default)("#envato-header-username-value").text(username);
        (0, _jquery.default)(".env-logged-in").show();
        (0, _jquery.default)(".env-anon").hide();
      } else {
        (0, _jquery.default)(".env-logged-in").hide();
        (0, _jquery.default)(".env-anon").show();
      }
      (0, _jquery.default)("#env-navbar-home-link").click(function (e) {
        _url.default.routeTo("/");
        e.preventDefault();
        return false;
      });
      let appRoute = container.lookup("route:application");
      (0, _jquery.default)("#envato-header-create a").click(function (e) {
        if (!username) {
          if (appRoute) {
            appRoute.send("showCreateAccount");
          }
        }
        e.preventDefault();
        return false;
      });
      (0, _jquery.default)("#envato-header-sign-in a").click(function (e) {
        if (appRoute) {
          appRoute.send("showLogin");
        }
        e.preventDefault();
        return false;
      });
      (0, _jquery.default)("#envato-header-sign-out a").click(function (e) {
        appRoute.send("logout");
        e.preventDefault();
        return false;
      });
    }
  };
});