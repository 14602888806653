define("discourse/plugins/discourse-envato/discourse/templates/connectors/category-custom-settings/discourse-envato", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <section class="field">
    <label>
      Web Crawler Title
      {{text-field value=this.category.custom_fields.envato_seo_title}}
    </label>
  </section>
  
  <section class="field">
    <label>
      Web Crawler Description
      {{text-field value=this.category.custom_fields.envato_seo_description}}
    </label>
  </section>
  */
  {
    "id": "sQlNBww7",
    "block": "[[[10,\"section\"],[14,0,\"field\"],[12],[1,\"\\n  \"],[10,\"label\"],[12],[1,\"\\n    Web Crawler Title\\n    \"],[1,[28,[35,2],null,[[\"value\"],[[30,0,[\"category\",\"custom_fields\",\"envato_seo_title\"]]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,\"section\"],[14,0,\"field\"],[12],[1,\"\\n  \"],[10,\"label\"],[12],[1,\"\\n    Web Crawler Description\\n    \"],[1,[28,[35,2],null,[[\"value\"],[[30,0,[\"category\",\"custom_fields\",\"envato_seo_description\"]]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"section\",\"label\",\"text-field\"]]",
    "moduleName": "discourse/plugins/discourse-envato/discourse/templates/connectors/category-custom-settings/discourse-envato.hbs",
    "isStrictMode": false
  });
});